import { Component, OnDestroy, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';

import { Subject, takeUntil } from 'rxjs';

import { TranslocoModule } from '@ngneat/transloco';

import { SurveyModule } from 'survey-angular-ui';

import { SurveyCreatorService } from '../survey-creator.service';

@Component({
  selector: 'app-survey-answer',
  templateUrl: './answer.component.html',
  standalone: true,
  imports: [SurveyModule, TranslocoModule],
})
export class SurveyAnswerComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    public surveyCreatorService: SurveyCreatorService,
    private readonly _route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this._retrieveSurveyView();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  private _retrieveSurveyView(): void {
    this._route.data.pipe(takeUntil(this._unsubscribeAll)).subscribe(({ surveyView }) => {
      if (surveyView) {
        this.surveyCreatorService.setSurveyView(surveyView);
      }
    });
  }
}
