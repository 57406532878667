import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable, ReplaySubject, tap } from 'rxjs';

import { Navigation } from './types';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private _httpClient: HttpClient;
  private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);
  private _apiUrl!: string;

  constructor(httpClient: HttpClient) {
    this._httpClient = httpClient;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for navigation
   */
  get navigation$(): Observable<Navigation> {
    return this._navigation.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Set API URL
   * @param url The API URL for navigation data
   */
  provideNavigation(url: string): void {
    this._apiUrl = url;
  }

  /**
   * Get all navigation data
   */
  get(): Observable<Navigation> {
    if (!this._apiUrl) {
      throw new Error('API URL not provided. Use provideNavigation(url) before calling get().');
    }

    return this._httpClient.get<Navigation>(this._apiUrl).pipe(
      tap(navigation => {
        this._navigation.next(navigation);
      }),
    );
  }
}
