import { inject, Injectable } from '@angular/core';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { merge } from 'lodash-es';

import { TgxConfirmationConfig } from './confirmation.types';
import { TgxConfirmationDialogComponent } from './dialog/dialog.component';

@Injectable({ providedIn: 'root' })
export class TgxConfirmationService {
  private _matDialog: MatDialog = inject(MatDialog);
  private _defaultConfig: TgxConfirmationConfig = {
    title: 'Confirm action',
    message: 'Are you sure you want to confirm this action?',
    icon: {
      show: true,
      name: 'heroicons_outline:exclamation-triangle',
      color: 'warn',
    },
    actions: {
      confirm: {
        show: true,
        label: 'Confirm',
        color: 'warn',
      },
      cancel: {
        show: true,
        label: 'Cancel',
      },
    },
    dismissible: false,
  };

  /**
   * Constructor
   */
  constructor() {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  open(config: TgxConfirmationConfig = {}): MatDialogRef<TgxConfirmationDialogComponent> {
    // Merge the user config with the default config
    const userConfig = merge({}, this._defaultConfig, config);

    // Open the dialog
    return this._matDialog.open(TgxConfirmationDialogComponent, {
      autoFocus: false,
      disableClose: !userConfig.dismissible,
      data: userConfig,
      panelClass: 'tgx-confirmation-dialog-panel',
    });
  }
}
