import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { RouterLink } from '@angular/router';

import { Subject, takeUntil } from 'rxjs';

import { TranslocoModule } from '@ngneat/transloco';

import { Breadcrumb, BreadcrumbService } from './breadcrumb.service';

@Component({
  selector: 'tgx-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css'],
  standalone: true,
  imports: [NgFor, NgIf, NgTemplateOutlet, RouterLink, TranslocoModule],
})
export default class TgxBreadcrumbComponent implements OnInit, OnDestroy {
  breadcrumbs: Breadcrumb[] = [];

  private _unsubscribe: Subject<any> = new Subject<any>();

  constructor(
    private _breadcrumbService: BreadcrumbService,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this._breadcrumbService.breadcrumbs$
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((updatedBreadcrumbs: Breadcrumb[]) => {
        this.breadcrumbs = updatedBreadcrumbs;
        this._changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this._unsubscribe.next(null);
    this._unsubscribe.complete();
  }
}
