import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';

import { getNestedValue } from '@tagmedev/ui-sdk/utils';

export type Breadcrumb = {
  complementLabel?: string;
  label: string;
  url: string;
};

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private _breadcrumbsSubject: BehaviorSubject<Breadcrumb[]> = new BehaviorSubject(
    [] as Breadcrumb[],
  );
  breadcrumbs$: Observable<Breadcrumb[]> = this._breadcrumbsSubject.asObservable();

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
  ) {
    this._router.events.subscribe(() => {
      const breadcrumbs = this.createBreadcrumbs(this._route.root);
      this._breadcrumbsSubject.next(breadcrumbs);
    });
  }

  private createBreadcrumbs(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: Breadcrumb[] = [],
  ): Breadcrumb[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot?.url?.map(segment => segment.path).join('/');

      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const { data = {}, resolve } = (child.snapshot?.routeConfig as any) ?? {};
      const { breadcrumb } = data;

      if (breadcrumb) {
        if (typeof breadcrumb === 'string') {
          breadcrumbs.push({ label: breadcrumb, url });
        }

        if (Array.isArray(breadcrumb) && resolve) {
          const [breadcrumbLabel, searchValue] = breadcrumb;
          const resolvedData = child.snapshot.data;
          const complementLabel = getNestedValue(resolvedData, searchValue);
          const breadcrumbItem = { label: breadcrumbLabel, url };

          if (complementLabel && typeof complementLabel !== 'object') {
            Object.assign(breadcrumbItem, { complementLabel });
          }

          breadcrumbs.push(breadcrumbItem);
        }
      }

      this.createBreadcrumbs(child, url, breadcrumbs);
    }

    return breadcrumbs;
  }
}
