import { CanActivateChildFn, CanActivateFn } from '@angular/router';

import { environment } from 'environments/environment';

export const SubdomainRedirectGuard: CanActivateFn | CanActivateChildFn = (_, state) => {
  if (environment.production) {
    const currentSubdomain = window.location.hostname.split('.')[0];
    const requestedPath = state.url.split('?')[0];

    if (currentSubdomain === 'review') {
      if (requestedPath.startsWith('/survey/')) {
        return true;
      }

      window.location.href = environment.url_site;

      return false;
    }
  }

  return true;
};
