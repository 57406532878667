import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { Observable, take } from 'rxjs';

import { SurveyService } from 'app/modules/reviews/survey/survey.service';

export const SurveysResolver: ResolveFn<any> = (): Observable<any> => {
  const surveyService = inject(SurveyService);
  return surveyService.retrieveAll().pipe(take(1));
};
