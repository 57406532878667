<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
  <span class="tgx-user-image-container">
    <img
      class="tgx-user-image"
      alt="Avatar"
      *ngIf="showAvatar && user?.avatar"
      [src]="user.avatar"
    />
    <mat-icon
      *ngIf="!showAvatar || !user?.avatar"
      [svgIcon]="'heroicons_outline:user-circle'"
    ></mat-icon>

    <!--
      <span
        class="absolute right-0 bottom-0 w-2 h-2 rounded-full"
        [ngClass]="{
          'mr-px mb-px': !showAvatar || !user?.avatar,
          'bg-green-500': user?.status === 'online',
          'bg-amber-500': user?.status === 'away',
          'bg-red-500': user?.status === 'busy',
          'bg-gray-400': user?.status === 'not-visible'
        }"
      ></span>
    -->
  </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
  <button mat-menu-item>
    <span class="tgx-user-content">
      <span>{{ 'signed_in' | transloco }}</span>
      <span class="tgx-user-email">{{ user.userName }}</span>
    </span>
  </button>
  <mat-divider class="tgx-user-menu-divider"></mat-divider>
  <button mat-menu-item>
    <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    <span>{{ 'profile' | transloco }}</span>
  </button>
  <button mat-menu-item>
    <mat-icon [svgIcon]="'heroicons_outline:cog-8-tooth'"></mat-icon>
    <span>{{ 'settings' | transloco }}</span>
  </button>
  <!--
    <button mat-menu-item [matMenuTriggerFor]="userStatus">
      <mat-icon [svgIcon]="'heroicons_outline:ellipsis-horizontal-circle'"></mat-icon>
      <span>Status</span>
    </button>
  -->
  <mat-divider class="tgx-user-menu-divider"></mat-divider>
  <button mat-menu-item (click)="signOut()">
    <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
    <span>{{ 'sign_out' | transloco }}</span>
  </button>
</mat-menu>

<mat-menu class="user-status-menu" #userStatus="matMenu">
  <button mat-menu-item (click)="updateUserStatus('online')">
    <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
    <span>Online</span>
  </button>
  <button mat-menu-item (click)="updateUserStatus('away')">
    <span class="w-4 h-4 mr-3 rounded-full bg-amber-500"></span>
    <span>Away</span>
  </button>
  <button mat-menu-item (click)="updateUserStatus('busy')">
    <span class="w-4 h-4 mr-3 rounded-full bg-red-500"></span>
    <span>Busy</span>
  </button>
  <button mat-menu-item (click)="updateUserStatus('not-visible')">
    <span class="w-4 h-4 mr-3 rounded-full bg-gray-400"></span>
    <span>Invisible</span>
  </button>
</mat-menu>
