import { Injectable } from '@angular/core';

import { TgxDrawerComponent } from './drawer.component';

@Injectable({ providedIn: 'root' })
export class TgxDrawerService {
  private _componentRegistry: Map<string, TgxDrawerComponent> = new Map<
    string,
    TgxDrawerComponent
  >();

  /**
   * Constructor
   */
  constructor() {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register drawer component
   *
   * @param name
   * @param component
   */
  registerComponent(name: string, component: TgxDrawerComponent): void {
    this._componentRegistry.set(name, component);
  }

  /**
   * Deregister drawer component
   *
   * @param name
   */
  deregisterComponent(name: string): void {
    this._componentRegistry.delete(name);
  }

  /**
   * Get drawer component from the registry
   *
   * @param name
   */
  getComponent(name: string): TgxDrawerComponent | undefined {
    return this._componentRegistry.get(name);
  }
}
