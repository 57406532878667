<ng-template #selectedThemeMode>
  @switch (config.scheme) {
    @case ('light') {
      <mat-icon [svgIcon]="'heroicons_solid:sun'"></mat-icon>
    }
    @case ('dark') {
      <mat-icon [svgIcon]="'heroicons_solid:moon'"></mat-icon>
    }
    @case ('auto') {
      <mat-icon [svgIcon]="'heroicons_solid:bolt'"></mat-icon>
    }
    @default {
      <mat-icon [svgIcon]="'heroicons_solid:sun'"></mat-icon>
    }
  }
</ng-template>

<button mat-icon-button [matMenuTriggerFor]="themeModeActions">
  <ng-container *ngTemplateOutlet="selectedThemeMode"></ng-container>
</button>

<mat-menu [xPosition]="'before'" #themeModeActions="matMenu">
  <button mat-menu-item (click)="setScheme('light')">
    <mat-icon [svgIcon]="'heroicons_solid:sun'"></mat-icon>
    <span>{{ 'theme_mode.light' | transloco }}</span>
  </button>
  <button mat-menu-item (click)="setScheme('dark')">
    <mat-icon [svgIcon]="'heroicons_solid:moon'"></mat-icon>
    <span>{{ 'theme_mode.dark' | transloco }}</span>
  </button>
  <button mat-menu-item (click)="setScheme('auto')">
    <mat-icon [svgIcon]="'heroicons_solid:bolt'"></mat-icon>
    <span>{{ 'theme_mode.auto' | transloco }}</span>
  </button>
</mat-menu>
