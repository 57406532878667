import {
  APP_INITIALIZER,
  ENVIRONMENT_INITIALIZER,
  EnvironmentProviders,
  importProvidersFrom,
  inject,
  Provider,
} from '@angular/core';

import { provideHttpClient, withInterceptors } from '@angular/common/http';

import { MATERIAL_SANITY_CHECKS } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

import merge from 'lodash-es/merge';

import { provideNgxMask } from 'ngx-mask';

import { provideToastr } from 'ngx-toastr';

import { TGX_MOCK_API_DEFAULT_DELAY, mockApiInterceptor } from '@tagmedev/ui-sdk/mock-api';

import { TgxConfig } from '@tagmedev/ui-sdk/services';
import { TGX_CONFIG } from '@tagmedev/ui-sdk/services';
import { TgxConfirmationService } from '@tagmedev/ui-sdk/services';
import { tgxLoadingInterceptor, TgxLoadingService } from '@tagmedev/ui-sdk/services';
import { TgxMediaWatcherService } from '@tagmedev/ui-sdk/services';
import { TgxPlatformService } from '@tagmedev/ui-sdk/services';
import { TgxSplashScreenService } from '@tagmedev/ui-sdk/services';
import { TgxUtilsService } from '@tagmedev/ui-sdk/services';
import { providePaginator } from '../paginator';

export type TgxProviderConfig = {
  mockApi?: {
    delay?: number;
    services?: any[];
  };
  tgx?: TgxConfig;
};

const DEFAULT_CONFIG: TgxProviderConfig = {
  mockApi: {
    delay: 0,
    services: undefined,
  },
  tgx: {
    layout: 'empty',
    scheme: 'light',
    screens: {
      sm: '600px',
      md: '960px',
      lg: '1280px',
      xl: '1440px',
    },
    theme: 'theme-brand',
    themes: [
      {
        id: 'theme-default',
        name: 'Default',
      },
      {
        id: 'theme-brand',
        name: 'Brand',
      },
      {
        id: 'theme-teal',
        name: 'Teal',
      },
      {
        id: 'theme-rose',
        name: 'Rose',
      },
      {
        id: 'theme-purple',
        name: 'Purple',
      },
      {
        id: 'theme-amber',
        name: 'Amber',
      },
    ],
  },
};

/**
 * Tgx provider
 */
export const provideTgx = (config?: TgxProviderConfig): Array<Provider | EnvironmentProviders> => {
  const mergedConfig = merge(DEFAULT_CONFIG, config);

  // Base providers
  const providers: Array<Provider | EnvironmentProviders> = [
    {
      // Disable 'theme' sanity check
      provide: MATERIAL_SANITY_CHECKS,
      useValue: {
        doctype: true,
        theme: false,
        version: true,
      },
    },
    {
      // Use the 'outline' appearance on Angular Material form fields by default
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
      },
    },
    {
      provide: TGX_MOCK_API_DEFAULT_DELAY,
      useValue: mergedConfig?.mockApi?.delay ?? 0,
    },
    {
      provide: TGX_CONFIG,
      useValue: mergedConfig?.tgx ?? {},
    },

    importProvidersFrom(MatDialogModule),
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(TgxConfirmationService),
      multi: true,
    },

    provideHttpClient(withInterceptors([tgxLoadingInterceptor])),
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(TgxLoadingService),
      multi: true,
    },

    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(TgxMediaWatcherService),
      multi: true,
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(TgxPlatformService),
      multi: true,
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(TgxSplashScreenService),
      multi: true,
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(TgxUtilsService),
      multi: true,
    },

    providePaginator(),

    provideNgxMask(),

    provideToastr(),
  ];

  // Mock Api services
  if (mergedConfig?.mockApi?.services) {
    providers.push(provideHttpClient(withInterceptors([mockApiInterceptor])), {
      provide: APP_INITIALIZER,
      deps: [...mergedConfig.mockApi.services],
      useFactory: () => (): any => null,
      multi: true,
    });
  }

  // Return the providers
  return providers;
};
