import { InjectionToken, APP_INITIALIZER } from '@angular/core';

import { provideTransloco, TranslocoService } from '@ngneat/transloco';

import { firstValueFrom } from 'rxjs';

import { TranslocoHttpLoader } from './transloco-http-loader';
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE, FALLBACK_LANGUAGE } from './config.constant';

export const ROOT_TRANSLATION_PATH = new InjectionToken<string>('Root Translation Path');

export function provideTranslate(rootTranslationPath: string) {
  return [
    provideTransloco({
      config: {
        availableLangs: AVAILABLE_LANGUAGES,
        defaultLang: DEFAULT_LANGUAGE,
        fallbackLang: FALLBACK_LANGUAGE,
        reRenderOnLangChange: true,
        prodMode: true,
      },
      loader: TranslocoHttpLoader,
    }),
    {
      provide: ROOT_TRANSLATION_PATH,
      useValue: rootTranslationPath,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (translocoService: TranslocoService) => {
        const defaultLang = translocoService.getDefaultLang();
        translocoService.setActiveLang(defaultLang);
        return () => firstValueFrom(translocoService.load(defaultLang));
      },
      multi: true,
      deps: [TranslocoService],
    },
  ];
}
