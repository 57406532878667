import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { NgClass, NgFor, NgIf } from '@angular/common';

import { BooleanInput } from '@angular/cdk/coercion';

import { MatIconModule } from '@angular/material/icon';

import { Subject, takeUntil } from 'rxjs';

import { TgxNavigationComponent } from '../../navigation.component';
import { TgxNavigationService } from '../../navigation.service';
import { TgxNavigationItem } from '../../navigation.types';

import { TgxNavigationBasicItemComponent } from '../basic/basic.component';
import { TgxNavigationCollapsableItemComponent } from '../collapsable/collapsable.component';
import { TgxNavigationDividerItemComponent } from '../divider/divider.component';
import { TgxNavigationSpacerItemComponent } from '../spacer/spacer.component';

@Component({
  selector: 'tgx-navigation-group-item',
  templateUrl: './group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    MatIconModule,
    NgFor,
    TgxNavigationBasicItemComponent,
    TgxNavigationCollapsableItemComponent,
    TgxNavigationDividerItemComponent,
    forwardRef(() => TgxNavigationGroupItemComponent),
    TgxNavigationSpacerItemComponent,
  ],
})
export class TgxNavigationGroupItemComponent implements OnInit, OnDestroy {
  /* eslint-disable @typescript-eslint/naming-convention */
  static ngAcceptInputType_autoCollapse: BooleanInput;
  /* eslint-enable @typescript-eslint/naming-convention */

  @Input() autoCollapse!: boolean;
  @Input() item!: TgxNavigationItem;
  @Input() name!: string;

  private _tgxNavigationComponent!: TgxNavigationComponent;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _tgxNavigationService: TgxNavigationService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Get the parent navigation component
    this._tgxNavigationComponent = this._tgxNavigationService.getComponent(this.name);

    // Subscribe to onRefreshed on the navigation component
    this._tgxNavigationComponent.onRefreshed.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      // Mark for check
      this._changeDetectorRef.markForCheck();
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
