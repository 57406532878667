import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';

import { Observable, take } from 'rxjs';

import { SurveyService } from 'app/modules/reviews/survey/survey.service';

export const SurveyResultResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
): Observable<any> => {
  const surveyId = route.paramMap.get('id');
  const surveyService = inject(SurveyService);

  return surveyService.retrieve(surveyId, true).pipe(take(1));
};
