export const SURVEY_THEME = {
  $primary: '#ff7f40',
  $secondary: '#06b4f5',
  '$main-color': '#ff7f40',
  '$main-hover-color': '#ff7f40',
  '$sjs-primary-backcolor': '#ff7f40',
  '$sjs-secondary-backcolor': '#06b4f5',
  '$sjs-special-red': '#de1c48',
  '$sjs-special-green': '#696970',
  '$sjs-border-light': '#30323b',
  '$sjs-primary-backcolor-dark': '#ff7f40',
};
