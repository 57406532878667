/* eslint-disable */
import { TgxNavigationItem } from '@tagmedev/ui-sdk/components';

export const defaultNavigation: TgxNavigationItem[] = [
  {
    id: 'tagme.reviews',
    type: 'group',
    title: 'Reviews',
    subtitle: '',
    children: [
      {
        id: 'tagme.reviews.surveys',
        type: 'basic',
        title: 'components.navigation.reviews.surveys',
        icon: 'heroicons_outline:queue-list',
        link: '/reviews/survey',
      },
      {
        id: 'tagme.reviews.templates',
        type: 'basic',
        title: 'components.navigation.reviews.templates',
        icon: 'heroicons_outline:square-2-stack',
        link: '/reviews/template',
      },
    ],
  },
];
