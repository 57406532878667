import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { Observable, take } from 'rxjs';

import { VenueService } from 'app/services/venue.service';

export const VenueListResolver: ResolveFn<any[]> = (): Observable<any[]> => {
  const venueService = inject(VenueService);
  return venueService.findByAllowedUser().pipe(take(1));
};
