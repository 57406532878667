import { inject, Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { catchError, Observable, of, switchMap, throwError } from 'rxjs';

import { TgxUserService } from '@tagmedev/ui-sdk/services';

import { AuthUtils } from 'app/core/auth/auth.utils';

import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private _authenticated: boolean = false;
  private _baseUrl = environment.user_manager_api;
  private _httpClient = inject(HttpClient);
  private _userService = inject(TgxUserService);

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  set user(user: any) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  get user(): any {
    const userData = localStorage.getItem('user') ?? '';

    if (userData) {
      return JSON.parse(userData);
    }

    return {};
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  forgotPassword(email: string): Observable<any> {
    return this._httpClient.post('api/auth/forgot-password', email);
  }

  resetPassword(password: string): Observable<any> {
    return this._httpClient.post('api/auth/reset-password', password);
  }

  signIn(credentials: { username: string; password: string }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }

    const endpoint = `${this._baseUrl}/oauth2/token`;

    const requestData = {
      usernameOrEmail: credentials.username,
      password: credentials.password,
      scope: 'STANDARD',
    };

    // return this._httpClient.post('api/auth/sign-in', credentials).pipe(
    return this._httpClient.post(endpoint, requestData).pipe(
      switchMap((response: any) => {
        const { accessToken } = response;

        this.accessToken = accessToken;

        this._authenticated = true;

        const decodedToken = AuthUtils.decodeToken(accessToken);

        this.user = decodedToken;

        this._userService.user = this.user;

        return of(response);
      }),
    );
  }

  signInUsingToken(): Observable<any> {
    // Sign in using the token
    return this._httpClient
      .post('api/auth/sign-in-with-token', {
        accessToken: this.accessToken,
      })
      .pipe(
        catchError(() => of(false)),
        switchMap((response: any) => {
          // Replace the access token with the new one if it's available on
          // the response object.
          //
          // This is an added optional step for better security. Once you sign
          // in using the token, you should generate a new one on the server
          // side and attach it to the response object. Then the following
          // piece of code can replace the token with the refreshed one.
          if (response.accessToken) {
            this.accessToken = response.accessToken;
          }

          // Set the authenticated flag to true
          this._authenticated = true;

          // Store the user on the user service
          this._userService.user = response.user;

          return of(true);
        }),
      );
  }

  signOut(): Observable<any> {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user');

    this._authenticated = false;

    return of(true);
  }

  signUp(user: {
    name: string;
    email: string;
    password: string;
    company: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/sign-up', user);
  }

  unlockSession(credentials: { email: string; password: string }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  check(): Observable<boolean> {
    if (this._authenticated) {
      return of(true);
    }

    if (!this.accessToken) {
      return of(false);
    }

    /* if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    } */

    // Temporary logic
    // Build endpoint to sign in using access token
    this._authenticated = true;

    this._userService.user = this.user;

    return of(true);
    // return this.signInUsingToken();
  }
}
