import { ApplicationConfig } from '@angular/core';

import {
  PreloadAllModules,
  provideRouter,
  withInMemoryScrolling,
  withPreloading,
} from '@angular/router';

import { provideHttpClient } from '@angular/common/http';

import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';

import { provideAnimations } from '@angular/platform-browser/animations';

import {
  provideIcons,
  provideNavigation,
  provideTgx,
  provideTranslate,
} from '@tagmedev/ui-sdk/providers';
import { Scheme } from '@tagmedev/ui-sdk/services';

import { appRoutes } from 'app/app.routes';
import { provideAuth } from 'app/core/auth/auth.provider';
import { mockApiServices } from 'app/mock-api';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideHttpClient(),
    provideRouter(
      appRoutes,
      withPreloading(PreloadAllModules),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
    ),
    provideIcons(),
    provideNavigation('api/common/navigation'),
    provideTranslate('assets/i18n'),
    provideTgx({
      mockApi: {
        delay: 0,
        services: mockApiServices,
      },
      tgx: {
        scheme: (localStorage.getItem('theme-mode') as Scheme) || 'light',
        theme: 'theme-brand',
      },
    }),
    provideAuth(),

    // Material Date Adapter
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'D',
        },
        display: {
          dateInput: 'DDD',
          monthYearLabel: 'LLL yyyy',
          dateA11yLabel: 'DD',
          monthYearA11yLabel: 'LLLL yyyy',
        },
      },
    },
  ],
};
