import { ElementRef, Injectable } from '@angular/core';

import { firstValueFrom } from 'rxjs';

import { StylesManager, Model, surveyLocalization } from 'survey-core';

import {
  ICreatorOptions,
  editorLocalization,
  localization,
  SurveyCreatorModel,
  UploadFileEvent,
} from 'survey-creator-core';

import { IVisualizationPanelOptions, VisualizationPanel } from 'survey-analytics';
import * as SurveyAnalyticsTabulator from 'survey-analytics/survey.analytics.tabulator';

import { TranslocoService } from '@ngneat/transloco';

import { fileToBase64 } from '@tagmedev/ui-sdk/utils';

import { environment } from 'environments/environment';

import { BlobService } from 'app/services/blob.service';

import 'survey-core/survey.i18n.js';

import 'survey-creator-core/survey-creator-core.i18n';

import { SurveyService } from './survey.service';
import { SURVEY_THEME } from './survey-theme';

import { PT_LOCALE } from './survey-locale';

import { TAGME_LIGHT_THEME, TAGME_DARK_THEME } from './survey-tagme-theme';

type SurveyView = {
  _id: string;
  model: Model;
};

export type Visualizations = 'panel' | 'table';

localization.currentLocale = 'pt';
editorLocalization.currentLocale = 'pt';
surveyLocalization.defaultLocale = 'pt';
surveyLocalization.supportedLocales = ['pt', 'en', 'de', 'es', 'fr', 'it'];

const VISUALIZATION_PANEL_OPTIONS: IVisualizationPanelOptions = {
  showPercentages: true,
  allowDragDrop: false,
  allowHideQuestions: false,
  haveCommercialLicense: true,
};

const CREATOR_OPTIONS: ICreatorOptions = {
  showLogicTab: true,
  isAutoSave: false,
  showJSONEditorTab: true,
  showThemeTab: true,
  showTranslationTab: true,
};

@Injectable({
  providedIn: 'root',
})
export class SurveyCreatorService {
  surveyCreatorModel: SurveyCreatorModel;
  survey: SurveyView;

  private _currentVisualization: Visualizations = 'panel';
  private _activeLocale: string;
  private _visualizationPanel: VisualizationPanel;
  private _visualizationTable: SurveyAnalyticsTabulator.Tabulator;
  private _translateLocale: string;

  constructor(
    private _blobService: BlobService,
    private _surverService: SurveyService,
    private _translocoService: TranslocoService,
  ) {
    this.survey = {
      _id: null,
      model: null,
    };

    this._translateLocale = PT_LOCALE;

    this._setTheme();
    this._setLocale();
  }

  init(): void {
    const creator = new SurveyCreatorModel(CREATOR_OPTIONS);
    this.surveyCreatorModel = creator;
    this.surveyCreatorModel.JSON = {};
    this.surveyCreatorModel.onUploadFile.add(this._uploadFiles.bind(this));
    const themeTabPlugin = creator.themeEditor;

    function addCustomTheme(theme, userFriendlyThemeName) {
      PT_LOCALE.theme.names[theme.themeName] = userFriendlyThemeName;
      themeTabPlugin.addTheme(theme);
    }

    addCustomTheme(TAGME_LIGHT_THEME, 'Tagme');
    addCustomTheme(TAGME_DARK_THEME, 'Tagme');

    creator.theme = TAGME_LIGHT_THEME;
  }

  setReadOnly(readOnly = false): void {
    this.surveyCreatorModel.readOnly = readOnly;
  }

  setSurveyView(surveyView: any): void {
    const { _id, dataQuestion, dataTheme } = surveyView;

    dataQuestion?.pages?.forEach((page: any) => {
      page.elements?.forEach((element: any) => {
        if (element.type === 'file') {
          element.storeDataAsText = false;
          element.waitForUpload = true;
        }
      });
    });

    const surveyJSON = {
      haveCommercialLicense: true,
      storeDataAsText: false,
      ...dataQuestion,
    };

    this.survey._id = _id;
    this.survey.model = new Model(surveyJSON);
    this.survey.model.applyTheme(dataTheme);

    this.survey.model.onUploadFiles.add(this._uploadFiles.bind(this));
    this.survey.model.onComplete.add(this._onSurveyComplete.bind(this));
  }

  generateDashboardVisualization(
    survey: any,
    visualizationPanelRef: ElementRef | undefined,
    visualizationTableRef: ElementRef | undefined,
  ): void {
    const answers = survey?.answers?.map(({ dataAnswer }) => dataAnswer);
    this.setSurveyView(survey);

    this._generateVisualizationPanel(answers, visualizationPanelRef);
    this._generateVisualizationTable(answers, visualizationTableRef);
  }

  setCurrentVisualization(visualization: Visualizations): void {
    this._currentVisualization = visualization;

    if (this._currentVisualization === 'panel') {
      this._visualizationPanel.locale = this._activeLocale;
    }

    if (this._currentVisualization === 'table') {
      this._visualizationTable.locale = this._activeLocale;
    }
  }

  private _generateVisualizationPanel(
    answers: any[],
    visualizationPanelRef: ElementRef | undefined,
  ): void {
    this._visualizationPanel = new VisualizationPanel(
      this.survey.model.getAllQuestions(),
      answers,
      {
        survey: this.survey.model,
        ...VISUALIZATION_PANEL_OPTIONS,
      },
    );
    this._visualizationPanel.locale = this._activeLocale;
    this._visualizationPanel.render(visualizationPanelRef?.nativeElement);
  }

  private _generateVisualizationTable(
    answers: any[],
    visualizationTableRef: ElementRef | undefined,
  ): void {
    this._visualizationTable = new SurveyAnalyticsTabulator.Tabulator(this.survey.model, answers);
    this._visualizationTable.locale = this._activeLocale;
    this._visualizationTable.render(visualizationTableRef?.nativeElement);
  }

  private _setLocale(): void {
    this._translocoService.langChanges$.subscribe(activeLocale => {
      this._activeLocale = activeLocale;

      if (this.survey.model) {
        this.survey.model.locale = activeLocale;
      }

      if (this.surveyCreatorModel) {
        this.surveyCreatorModel.locale = activeLocale;
      }

      if (this._visualizationPanel && this._currentVisualization === 'panel') {
        this._visualizationPanel.locale = activeLocale;
      }

      if (this._visualizationTable && this._currentVisualization === 'table') {
        this._visualizationTable.locale = activeLocale;
      }
    });
  }

  private _setTheme(): void {
    const defaultThemeColors = StylesManager.ThemeColors['default'];

    for (const [key, value] of Object.entries(SURVEY_THEME)) {
      defaultThemeColors[key] = value;
    }

    StylesManager.applyTheme();
  }

  private _onSurveyComplete(): void {
    const dataAnswer = this.survey.model.data;

    const requestData = {
      dataAnswer,
      questionId: this.survey._id,
    };

    firstValueFrom(this._surverService.createAnswer(requestData));
  }

  private _uploadFile(data: any, callback: any): void {
    this._blobService.upload(data).subscribe(response => {
      const { blobUrl } = response;
      const fileUrl = `${environment.imageUrl}/thumbs/${blobUrl}`;
      callback('success', fileUrl);
    });
  }

  private async _uploadFiles(_, options: UploadFileEvent): Promise<void> {
    const { files } = options;

    for (const file of files) {
      try {
        const base64File = await fileToBase64(file);

        const requestData = {
          base64: true,
          file: base64File,
          fileName: file?.name,
          folder: 'Survey/',
        };

        this._uploadFile(requestData, options.callback);
      } catch (error) {
        options.callback('error', null);
        console.error(error);
      }
    }
  }
}
