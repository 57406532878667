import { EnvironmentProviders, Provider } from '@angular/core';

import { MatPaginatorIntl } from '@angular/material/paginator';

import { PaginatorIntlService } from './service';

export const providePaginator = (): Array<Provider | EnvironmentProviders> => {
  return [
    {
      provide: MatPaginatorIntl,
      useClass: PaginatorIntlService,
    },
  ];
};
