<!-- Flippable card -->
<ng-container *ngIf="flippable">
  <!-- Front -->
  <div class="tgx-card-front">
    <ng-content select="[tgxCardFront]"></ng-content>
  </div>

  <!-- Back -->
  <div class="tgx-card-back">
    <ng-content select="[tgxCardBack]"></ng-content>
  </div>
</ng-container>

<!-- Normal card -->
<ng-container *ngIf="!flippable">
  <!-- Content -->
  <ng-content></ng-content>

  <!-- Expansion -->
  <div class="tgx-card-expansion" *ngIf="expanded" [@expandCollapse]>
    <ng-content select="[tgxCardExpansion]"></ng-content>
  </div>
</ng-container>
