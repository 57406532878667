import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SurveyQuestionService {
  private _baseUrl = `${environment.review_api}/survey/questions`;

  constructor(private _httpClient: HttpClient) {}

  retrieveAll(): Observable<any[]> {
    const endpoint = this._baseUrl;
    return this._httpClient.get<any[]>(endpoint);
  }

  retrieve(id: string, withAnswers = false): Observable<any> {
    const endpoint = `${this._baseUrl}/${id}?withAnswers=${withAnswers}`;
    return this._httpClient.get<any>(endpoint);
  }

  retrieveView(id: string, withAnswers = false): Observable<any> {
    const endpoint = `${this._baseUrl}/view/${id}?withAnswers=${withAnswers}`;
    return this._httpClient.get<any>(endpoint);
  }

  create(data: any): Observable<any> {
    const endpoint = this._baseUrl;
    return this._httpClient.post<any[]>(endpoint, data);
  }

  update(id: string, data: any): Observable<any> {
    const endpoint = `${this._baseUrl}/${id}`;
    return this._httpClient.put<any>(endpoint, data);
  }

  remove(id: string): Observable<any> {
    const endpoint = `${this._baseUrl}/${id}`;
    return this._httpClient.delete<any>(endpoint);
  }
}
