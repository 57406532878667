export function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (event: ProgressEvent<FileReader>) {
      if (typeof event?.target?.result === 'string') {
        resolve(event.target.result);
      }
    };

    reader.onerror = function () {
      reject(new Error('Failed to convert file to base64.'));
    };

    reader.readAsDataURL(file);
  });
}
