import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SurveyAnswerService {
  private _baseUrl = `${environment.review_api}/survey/answers`;

  constructor(private _httpClient: HttpClient) {}

  create(data: any): Observable<any> {
    const endpoint = this._baseUrl;
    return this._httpClient.post<any>(endpoint, data);
  }
}
