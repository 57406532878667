import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';

import { Observable, catchError, take, throwError } from 'rxjs';

import { SurveyService } from 'app/modules/reviews/survey/survey.service';

export const SurveyResolver: ResolveFn<any> = (route: ActivatedRouteSnapshot): Observable<any> => {
  const router = inject(Router);
  const surveyId = route.paramMap.get('id');
  const surveyService = inject(SurveyService);

  return surveyService.retrieve(surveyId).pipe(
    take(1),
    catchError(error => {
      if (error?.error?.statusCode === 400) {
        router.navigate(['/reviews']);
      }

      return throwError(() => error);
    }),
  );
};
