import { Injectable } from '@angular/core';

import { TgxNavigationItem } from '@tagmedev/ui-sdk/components';
import { TgxMockApiService } from '@tagmedev/ui-sdk/mock-api';

import { defaultNavigation } from 'app/mock-api/common/navigation/data';

import { cloneDeep } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class NavigationMockApi {
  private readonly _defaultNavigation: TgxNavigationItem[] = defaultNavigation;

  /**
   * Constructor
   */
  constructor(private _tgxMockApiService: TgxMockApiService) {
    // Register Mock API handlers
    this.registerHandlers();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    // -----------------------------------------------------------------------------------------------------
    // @ Navigation - GET
    // -----------------------------------------------------------------------------------------------------
    this._tgxMockApiService.onGet('api/common/navigation').reply(() => {
      // Return the response
      return [
        200,
        {
          default: cloneDeep(this._defaultNavigation),
        },
      ];
    });
  }
}
