import { InjectionToken, APP_INITIALIZER } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { firstValueFrom } from 'rxjs';

import { NavigationService } from './service';

const ROOT_API_URL = new InjectionToken<string>('Root API URL');

function provideNavigationFactory(httpClient: HttpClient, apiUrl: string) {
  const navigationService = new NavigationService(httpClient);
  navigationService.provideNavigation(apiUrl);
  return navigationService;
}

function provideNavigationInitializer(navigationService: NavigationService) {
  return () => firstValueFrom(navigationService.get());
}

const NAVIGATION_INITIALIZER = {
  provide: APP_INITIALIZER,
  useFactory: provideNavigationInitializer,
  multi: true,
  deps: [NavigationService],
};

export function provideNavigation(apiUrl: string) {
  return [
    {
      provide: ROOT_API_URL,
      useValue: apiUrl,
    },
    {
      provide: NavigationService,
      useFactory: provideNavigationFactory,
      deps: [HttpClient, ROOT_API_URL],
    },
    NAVIGATION_INITIALIZER,
  ];
}
