import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BlobService {
  private _baseUrl = environment.tagme_api.host;

  constructor(private _httpClient: HttpClient) {}

  upload(data: any): Observable<any> {
    const headers = {
      apptoken: environment.tagme_api.app_token,
      apitoken: environment.tagme_api.api_token,
    };

    const endpoint = `${this._baseUrl}/Blob`;

    return this._httpClient.post<any>(endpoint, data, { headers });
  }
}
