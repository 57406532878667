import { Injectable } from '@angular/core';

import { Observable, ReplaySubject, of } from 'rxjs';

import { User } from './user.types';

@Injectable({ providedIn: 'root' })
export class TgxUserService {
  // private _httpClient = inject(HttpClient);
  private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: User) {
    // Store the value
    this._user.next(value);
  }

  get user$(): Observable<User> {
    return this._user.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current signed-in user data
   */
  // TODO: Create integration after with endpoint
  /* get(): Observable<User> {
    return this._httpClient.get<User>('api/common/user').pipe(
      tap(user => {
        this._user.next(user);
      }),
    );
  } */

  /**
   * Update the user
   *
   * @param user
   */
  update(user: User): Observable<any> {
    this.user = user;
    return of(user);

    // TODO: Create integration after with endpoint
    /* return this._httpClient.patch<User>('api/common/user', { user }).pipe(
      map(response => {
        this._user.next(response);
      }),
    ); */
  }
}
