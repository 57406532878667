import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';

import { NgFor, NgTemplateOutlet } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';

import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'tgx-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'languages',
  standalone: true,
  imports: [MatButtonModule, MatMenuModule, NgTemplateOutlet, NgFor],
})
export class TgxLanguagesComponent implements OnInit {
  availableLangs: { id: string; label: string }[] = [];
  activeLang!: string;
  flagCodes: any;

  /**
   * Constructor
   */
  constructor(private _translocoService: TranslocoService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Get the available languages from transloco
    this.availableLangs = this._translocoService.getAvailableLangs() as {
      id: string;
      label: string;
    }[];

    // Subscribe to language changes
    this._translocoService.langChanges$.subscribe(activeLang => {
      // Get the active lang
      this.activeLang = activeLang;
    });

    // Set the country iso codes for languages for flags
    this.flagCodes = {
      pt: 'br',
      en: 'us',
      de: 'de',
      es: 'es',
      fr: 'fr',
      it: 'it',
    };
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Set the active lang
   *
   * @param lang
   */
  setActiveLang(lang: string): void {
    // Set the active lang
    this._translocoService.setActiveLang(lang);
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
