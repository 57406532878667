import { localization } from 'survey-creator-core';

export const PT_LOCALE = localization.getLocale('pt');
PT_LOCALE.theme.names.default = 'Padrão';
PT_LOCALE.pv.rateColorMode.default = 'Padrão';
PT_LOCALE.theme.themeModePanels = 'Padrão';
PT_LOCALE.theme.primaryDefaultColor = 'Padrão';
PT_LOCALE.theme.names.sharp = 'Intenso';
PT_LOCALE.theme.names.borderless = 'Sem Borda';
PT_LOCALE.theme.names.plain = 'Simples';
PT_LOCALE.theme.themePaletteLight = 'Claro';
