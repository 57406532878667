import { Route } from '@angular/router';

import { LayoutComponent } from '@tagmedev/ui-sdk/layout';

import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';

import { SubdomainRedirectGuard } from './guards/subdomain-redirect.guard';
import { SurveyAnswerComponent } from './modules/reviews/survey/answer/answer.component';
import { SurveyViewResolver } from './resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  // Redirect empty path to '/dashboard'
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'reviews/survey/list',
  },

  // Redirect signed-in user to the '/dashboard'
  //
  // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  {
    path: 'signed-in-redirect',
    pathMatch: 'full',
    redirectTo: 'reviews/survey/list',
  },

  // Auth routes for guests
  {
    path: '',
    canActivate: [NoAuthGuard, SubdomainRedirectGuard],
    canActivateChild: [NoAuthGuard, SubdomainRedirectGuard],
    component: LayoutComponent,
    children: [
      {
        path: 'confirmation-required',
        loadChildren: () =>
          import('app/modules/auth/confirmation-required/confirmation-required.routes'),
      },
      {
        path: 'forgot-password',
        loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes'),
      },
      {
        path: 'reset-password',
        loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes'),
      },
      {
        path: 'sign-in',
        loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes'),
      },
      {
        path: 'sign-up',
        loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes'),
      },
    ],
  },

  // Auth routes for authenticated users
  {
    path: '',
    canActivate: [AuthGuard, SubdomainRedirectGuard],
    canActivateChild: [AuthGuard, SubdomainRedirectGuard],
    component: LayoutComponent,
    children: [
      {
        path: 'sign-out',
        loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes'),
      },
      {
        path: 'unlock-session',
        loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes'),
      },
    ],
  },

  // Admin routes
  {
    path: '',
    canActivate: [AuthGuard, SubdomainRedirectGuard],
    canActivateChild: [AuthGuard, SubdomainRedirectGuard],
    component: LayoutComponent,
    data: {
      layout: 'dense',
    },
    resolve: {
      initialData: initialDataResolver,
    },
    children: [
      {
        path: 'venue',
        loadChildren: () => import('app/modules/admin/venue/pre-register/pre-register.routes'),
        data: {
          layout: 'empty',
        },
      },
      {
        path: 'menu',
        loadChildren: () => import('app/modules/admin/menu/menu.routes'),
      },
    ],
  },

  // Reviews routes
  {
    path: '',
    component: LayoutComponent,
    canActivate: [SubdomainRedirectGuard],
    canActivateChild: [SubdomainRedirectGuard],
    data: {
      layout: 'dense',
    },
    resolve: {
      initialData: initialDataResolver,
    },
    children: [
      {
        path: 'reviews',

        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'survey',
          },
          {
            path: 'survey',
            loadChildren: () => import('./modules/reviews/survey/survey.routes'),
          },
          {
            canActivate: [AuthGuard],
            canActivateChild: [AuthGuard],
            path: 'template',
            loadChildren: () => import('./modules/reviews/template/template.routes'),
          },
        ],
      },
      {
        path: 'survey/:id',
        component: SurveyAnswerComponent,
        data: {
          layout: 'empty',
        },
        resolve: {
          surveyView: SurveyViewResolver,
        },
      },
    ],
  },
];
