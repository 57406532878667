export function getNestedValue(obj: any, reference: string): any {
  const keys = reference.split('.');
  let value = obj;

  for (const key of keys) {
    const currentValue = value[key];

    if (!currentValue) {
      return;
    }

    value = value[key];
  }

  return value;
}
