import { inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class IconsService {
  constructor() {
    const domSanitizer = inject(DomSanitizer);
    const matIconRegistry = inject(MatIconRegistry);

    // Register icon sets
    matIconRegistry.addSvgIconSet(
      domSanitizer.bypassSecurityTrustResourceUrl(
        '@tagmedev/ui-sdk/assets/icons/material-twotone.svg',
      ),
    );
    matIconRegistry.addSvgIconSetInNamespace(
      'mat_outline',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '@tagmedev/ui-sdk/assets/icons/material-outline.svg',
      ),
    );
    matIconRegistry.addSvgIconSetInNamespace(
      'mat_solid',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '@tagmedev/ui-sdk/assets/icons/material-solid.svg',
      ),
    );
    matIconRegistry.addSvgIconSetInNamespace(
      'feather',
      domSanitizer.bypassSecurityTrustResourceUrl('@tagmedev/ui-sdk/assets/icons/feather.svg'),
    );
    matIconRegistry.addSvgIconSetInNamespace(
      'heroicons_outline',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '@tagmedev/ui-sdk/assets/icons/heroicons-outline.svg',
      ),
    );
    matIconRegistry.addSvgIconSetInNamespace(
      'heroicons_solid',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '@tagmedev/ui-sdk/assets/icons/heroicons-solid.svg',
      ),
    );
    matIconRegistry.addSvgIconSetInNamespace(
      'heroicons_mini',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '@tagmedev/ui-sdk/assets/icons/heroicons-mini.svg',
      ),
    );
  }
}
