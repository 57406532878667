<!-- Loading bar -->
<tgx-loading-bar></tgx-loading-bar>

<!-- Logo -->
<ng-template #logoToRender>
  <ng-container *ngIf="navigationAppearance === 'dense'; else defaultLogo">
    <ng-container *ngIf="openNavigationWithHover; else denseLogo">
      <ng-container *ngTemplateOutlet="defaultLogo"></ng-container>
    </ng-container>
  </ng-container>

  <ng-template #denseLogo>
    <img
      class="tgx-dense-logo"
      src="@tagmedev/ui-sdk/assets/images/logo/logo.png"
      alt="Logo image"
    />
  </ng-template>

  <ng-template #defaultLogo>
    <img
      class="tgx-default-logo"
      src="@tagmedev/ui-sdk/assets/images/logo/logo-full.svg"
      alt="Logo image"
    />
  </ng-template>
</ng-template>

<!-- Navigation -->
<tgx-navigation
  class="tgx-dense-layout-navigation dark"
  [appearance]="navigationAppearance"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation.default"
  [opened]="!isScreenSmall"
  (hoverChanged)="openNavigationWithHover = $event"
>
  <!-- Navigation header hook -->
  <ng-container tgxNavigationContentHeader>
    <!-- Logo -->
    <div class="tgx-navigation-logo-container">
      <ng-container *ngTemplateOutlet="logoToRender"></ng-container>
    </div>
  </ng-container>
</tgx-navigation>

<!-- Wrapper -->
<div class="tgx-dense-layout-wrapper">
  <!-- Header -->
  <div class="tgx-dense-layout-header">
    <div class="tgx-dense-layout-header-content">
      <!-- Navigation toggle button -->
      <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
        <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
      </button>
      <!-- Navigation appearance toggle button -->
      <button
        class="tgx-navigation-appearance-toggle-button"
        mat-icon-button
        (click)="toggleNavigationAppearance()"
      >
        <mat-icon [svgIcon]="'heroicons_outline:arrows-right-left'"></mat-icon>
      </button>
    </div>
    <!-- Components -->
    <div class="tgx-dense-layout-header-components">
      <tgx-languages></tgx-languages>
      <tgx-fullscreen class="tgx-header-component-fullscreen-button"></tgx-fullscreen>
      <tgx-theme-mode-switcher></tgx-theme-mode-switcher>
      <tgx-user [showAvatar]="false"></tgx-user>
    </div>
  </div>

  <!-- Content -->
  <div class="tgx-dense-layout-content">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer -->
  <div class="tgx-dense-layout-footer">
    <span class="tgx-footer-copyright">Tagme &copy; {{ currentYear }}</span>
  </div>
</div>
