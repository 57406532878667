import { AvailableLangs } from '@ngneat/transloco';

export const AVAILABLE_LANGUAGES: AvailableLangs = [
  {
    id: 'pt',
    label: 'Português',
  },
  {
    id: 'en',
    label: 'English',
  },
  {
    id: 'de',
    label: 'Deutsch',
  },
  {
    id: 'es',
    label: 'Español',
  },
  {
    id: 'fr',
    label: 'Français',
  },
  {
    id: 'it',
    label: 'Italiano',
  },
];

export const DEFAULT_LANGUAGE = 'pt';

export const FALLBACK_LANGUAGE = 'pt';
