import { Inject, Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Translation, TranslocoLoader } from '@ngneat/transloco';

import { ROOT_TRANSLATION_PATH } from './provider';
import { Observable, catchError, forkJoin, from, map, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private _httpClient: HttpClient;
  private _rootTranslationPath: string;

  constructor(httpClient: HttpClient, @Inject(ROOT_TRANSLATION_PATH) rootTranslationPath: string) {
    this._httpClient = httpClient;
    this._rootTranslationPath = rootTranslationPath;
  }

  getTranslation(lang: string) {
    const internalTranslation = this._getInternalTranslation(lang);
    const splittedMultiplePaths = this._rootTranslationPath.split('|');
    const obs: Observable<any>[] = [internalTranslation];

    splittedMultiplePaths.forEach(path => {
      const formattedPath = this._formatTranslationPath(path);
      obs.push(
        this._httpClient.get<Translation>(`${formattedPath}/${lang}.json`).pipe(
          catchError(error => {
            console.error(`Error loading translation from ${formattedPath}/${lang}.json`, error);
            return of({});
          }),
        ),
      );
    });

    return forkJoin([...obs]).pipe(
      map(([translation, vendor]) => {
        return { ...translation, ...vendor };
      }),
    );
  }

  private _getInternalTranslation(lang: string): Observable<any> {
    return from(import(`@tagmedev/ui-sdk/assets/i18n/${lang}.json`)).pipe(
      map(module => module.default),
      catchError(error => {
        console.error('Error loading internal translation', error);
        return of({});
      }),
    );
  }

  private _formatTranslationPath(path: string): string {
    return path.replace(/\/+$/, '');
  }
}
