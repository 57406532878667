import { Injectable } from '@angular/core';

import { MatPaginatorIntl } from '@angular/material/paginator';

import { TranslocoService } from '@ngneat/transloco';

import { take } from 'rxjs';

const PAGINATOR_TRANSLATION = {
  itemsPerPageLabel: 'ui_sdk.components.paginator.items_per_page_label',
  nextPageLabel: 'ui_sdk.components.paginator.next_page_label',
  previousPageLabel: 'ui_sdk.components.paginator.previous_page_label',
  firstPageLabel: 'ui_sdk.components.paginator.first_page_label',
  lastPageLabel: 'ui_sdk.components.paginator.last_page_label',
  of: 'ui_sdk.generics.of',
};

@Injectable()
export class PaginatorIntlService extends MatPaginatorIntl {
  constructor(private _translocoService: TranslocoService) {
    super();

    this._updatePaginatorIntl();

    this._translocoService.langChanges$.subscribe(() => {
      this._updatePaginatorIntl();
    });
  }

  private _updatePaginatorIntl() {
    this._translocoService
      .selectTranslate(Object.values(PAGINATOR_TRANSLATION))
      .pipe(take(1))
      .subscribe(translations => {
        const translationKeys = Object.keys(PAGINATOR_TRANSLATION);

        for (const translationKey of translationKeys) {
          const index = translationKeys.indexOf(translationKey);
          const translatedValue = translations[index];

          if (translationKey === 'of') {
            this.getRangeLabel = this._getRangeLabel(translatedValue);
          } else if (translationKey in this) {
            (this as any)[translationKey] = translatedValue;
          }
        }

        this.changes.next();
      });
  }

  private _getRangeLabel(translatedValue: string) {
    return (page: number, pageSize: number, length: number): string => {
      if (length === 0 || pageSize === 0) {
        return `0 ${translatedValue} ${length}`;
      }

      length = Math.max(length, 0);

      const startIndex = page * pageSize;

      const endIndex =
        startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

      return `${startIndex + 1} - ${endIndex} ${translatedValue} ${length}`;
    };
  }
}
