import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

import { NgIf } from '@angular/common';

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';

import { tgxAnimations } from '@tagmedev/ui-sdk/animations';

import { TgxCardFace } from './card.types';

@Component({
  selector: 'tgx-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: tgxAnimations,
  exportAs: 'tgxCard',
  standalone: true,
  imports: [NgIf],
})
export class TgxCardComponent implements OnChanges {
  /* eslint-disable @typescript-eslint/naming-convention */
  static ngAcceptInputType_expanded: BooleanInput;
  static ngAcceptInputType_flippable: BooleanInput;
  /* eslint-enable @typescript-eslint/naming-convention */

  @Input() expanded: boolean = false;
  @Input() face: TgxCardFace = 'front';
  @Input() flippable: boolean = false;

  /**
   * Constructor
   */
  constructor() {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Host binding for component classes
   */
  @HostBinding('class') get classList(): any {
    /* eslint-disable @typescript-eslint/naming-convention */
    return {
      'tgx-card-expanded': this.expanded,
      'tgx-card-face-back': this.flippable && this.face === 'back',
      'tgx-card-face-front': this.flippable && this.face === 'front',
      'tgx-card-flippable': this.flippable,
    };
    /* eslint-enable @typescript-eslint/naming-convention */
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On changes
   *
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    // Expanded
    if ('expanded' in changes) {
      // Coerce the value to a boolean
      this.expanded = coerceBooleanProperty(changes['expanded'].currentValue);
    }

    // Flippable
    if ('flippable' in changes) {
      // Coerce the value to a boolean
      this.flippable = coerceBooleanProperty(changes['flippable'].currentValue);
    }
  }
}
