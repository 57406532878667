import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { Subject, takeUntil } from 'rxjs';

import { TranslocoModule } from '@ngneat/transloco';

import { TgxConfig, TgxConfigService, Scheme } from '@tagmedev/ui-sdk/services';

@Component({
  selector: 'tgx-theme-mode-switcher',
  templateUrl: './theme-mode-switcher.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatButtonModule, MatIconModule, MatMenuModule, NgTemplateOutlet, TranslocoModule],
})
export class TgxThemeModeSwitcherComponent implements OnInit, OnDestroy {
  config!: TgxConfig;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(private _tgxConfigService: TgxConfigService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this._tgxConfigService.config$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config: TgxConfig) => {
        this.config = config;
        this.saveSelectedThemeModeToStorage(config.scheme as any);
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  saveSelectedThemeModeToStorage(scheme: Scheme): void {
    localStorage.setItem('theme-mode', scheme);
  }

  setScheme(scheme: Scheme): void {
    this._tgxConfigService.config = { scheme };
    this.saveSelectedThemeModeToStorage(scheme);
  }
}
