import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { isEmpty, omit } from 'lodash-es';

import { Observable, ReplaySubject, firstValueFrom, throwError } from 'rxjs';
import { catchError, finalize, map, take, tap } from 'rxjs/operators';

import { TranslocoService } from '@ngneat/transloco';

import { ToastrService } from 'ngx-toastr';

import { SurveyAnswerService } from 'app/services/survey-answer.service';
import { SurveyQuestionService } from 'app/services/survey-question.service';

type LoadingContexts = 'surveys' | 'survey' | 'remove';

@Injectable({
  providedIn: 'root',
})
export class SurveyService {
  isLoading: ReplaySubject<[LoadingContexts, boolean]>;
  surveys: any[];
  surveysChanged: ReplaySubject<any[]>;

  private _translatedMessages = {
    genericError: 'toastr_messages.generic.error',
    createAnswer: 'toastr_messages.reviews.surveys.create_answer',
    create: 'toastr_messages.reviews.surveys.create',
    update: 'toastr_messages.reviews.surveys.update',
    remove: 'toastr_messages.reviews.surveys.remove',
    required: 'toastr_messages.reviews.surveys.required',
  };

  constructor(
    private _router: Router,
    private _surveyAnswerService: SurveyAnswerService,
    private _surveyQuestionService: SurveyQuestionService,
    private _translocoService: TranslocoService,
    private _toastrService: ToastrService,
  ) {
    this.isLoading = new ReplaySubject();
    this.surveysChanged = new ReplaySubject();
    this._setTranslatedMessages();
  }

  retrieveAll(): Observable<any[]> {
    this.isLoading.next(['surveys', true]);

    return this._surveyQuestionService.retrieveAll().pipe(
      map((surveys: any[]) => {
        this.surveys = surveys;
        this.surveysChanged.next(surveys);
        return surveys;
      }),
      finalize(() => this.isLoading.next(['surveys', false])),
      catchError(this._handleError.bind(this)),
    );
  }

  retrieve(surveyId: string, withAnswers = false): Observable<any[]> {
    this.isLoading.next(['survey', true]);

    return this._surveyQuestionService.retrieve(surveyId, withAnswers).pipe(
      map((survey: any) => survey),
      finalize(() => this.isLoading.next(['survey', false])),
      catchError(this._handleError.bind(this)),
    );
  }

  retrieveView(surveyId: string, withAnswers = false): Observable<any[]> {
    return this._surveyQuestionService.retrieveView(surveyId, withAnswers).pipe(
      map((survey: any) => survey),
      catchError(this._handleError.bind(this)),
    );
  }

  createOrUpdate(data: any): void {
    const { _id } = data;
    const requestData = omit(data, '_id');

    if (isEmpty(requestData.dataQuestion)) {
      this._toastrService.warning(this._translatedMessages.required);
      this.isLoading.next(['survey', false]);
      return;
    }

    if (_id) {
      firstValueFrom(this._update(_id, requestData));
    } else {
      firstValueFrom(this._create(requestData));
    }
  }

  createAnswer(data: any): Observable<any> {
    return this._surveyAnswerService.create(data).pipe(
      tap(() => {
        this._toastrService.success(this._translatedMessages.createAnswer);
      }),
      catchError(this._handleError.bind(this)),
    );
  }

  remove(surveyId: string): Observable<any> {
    this.isLoading.next(['remove', true]);

    return this._surveyQuestionService.remove(surveyId).pipe(
      tap(() => {
        this._toastrService.success(this._translatedMessages.remove);
        firstValueFrom(this.retrieveAll());
      }),
      finalize(() => this.isLoading.next(['remove', true])),
      catchError(this._handleError.bind(this)),
    );
  }

  private _create(data: any): Observable<any> {
    return this._surveyQuestionService.create(data).pipe(
      tap(response => {
        const { _id } = response;
        const editUrl = `/reviews/survey/${_id}/edit`;

        this._toastrService.success(this._translatedMessages.create);
        this._router.navigateByUrl(editUrl);
      }),
      finalize(() => this.isLoading.next(['survey', false])),
      catchError(this._handleError.bind(this)),
    );
  }

  private _update(surveyId: string, data: any): Observable<any> {
    return this._surveyQuestionService.update(surveyId, data).pipe(
      tap(() => {
        this._toastrService.success(this._translatedMessages.update);
      }),
      finalize(() => this.isLoading.next(['survey', false])),
      catchError(this._handleError.bind(this)),
    );
  }

  private _handleError(err: any): Observable<never> {
    const { error = {} } = err;
    const { message } = error;

    this._toastrService.error(message ?? this._translatedMessages.genericError);

    return throwError(() => err);
  }

  private _setTranslatedMessages(): void {
    const translationKeys = Object.keys(this._translatedMessages);
    const translationValues = Object.values(this._translatedMessages);

    this._translocoService
      .selectTranslate(translationValues)
      .pipe(take(1))
      .subscribe(translations => {
        for (const translationKey of translationKeys) {
          const index = translationKeys.indexOf(translationKey);
          this._translatedMessages[translationKey] = translations[index];
        }
      });
  }
}
