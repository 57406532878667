import { Component, ElementRef, Input, OnInit, ViewChild, signal } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { TranslocoModule } from '@ngneat/transloco';

import TgxBreadcrumbComponent from '../breadcrumb/breadcrumb.component';

type HeadingAlignments = 'default' | 'column' | 'row';

@Component({
  selector: 'tgx-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.css'],
  standalone: true,
  imports: [TgxBreadcrumbComponent, NgClass, NgIf, TranslocoModule],
})
export class TgxHeadingComponent implements OnInit {
  @Input() alignment?: HeadingAlignments = 'default';

  @ViewChild('actionsWrapper') actionsWrapper!: ElementRef;

  pageTitle = signal('');

  constructor(private _route: ActivatedRoute) {}

  ngOnInit(): void {
    this._route.data.subscribe(({ pageTitle }) => {
      if (pageTitle) {
        this.pageTitle.set(pageTitle);
      }
    });
  }

  hasActionsContent(): boolean {
    return this.actionsWrapper?.nativeElement?.children?.length > 0;
  }
}
