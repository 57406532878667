<div
  [ngClass]="{
    'tgx-heading': true,
    'tgx-heading-default': alignment === 'default',
    'tgx-heading-column': alignment === 'column',
    'tgx-heading-row': alignment === 'row'
  }"
>
  <div [ngClass]="{ 'tgx-heading-content': hasActionsContent() }">
    @if (pageTitle(); as title) {
      <!-- Title -->
      <div class="tgx-heading-title">
        {{ title | transloco }}
      </div>
    }

    <tgx-breadcrumb></tgx-breadcrumb>
  </div>

  <!-- Actions -->
  <div class="tgx-heading-actions" #actionsWrapper>
    <ng-content></ng-content>
  </div>
</div>
