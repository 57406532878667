export const environment = {
  production: false,
  review_api: 'https://tagme-reviews-dev.azurewebsites.net',
  user_manager_api: 'https://usermanager-dev.azurewebsites.net',
  url_site: 'https://apps-dev.tagme.com.br',
  url_review: '',
  tagme_api: {
    app_token: 'SuperDEVT3@ADM1n@T@gm3',
    api_token: 'jitIFNkQeCARIDEyFOMirAndaRuLOUROkNfA',
    host: 'https://tagme-api-dev.tagme.com.br',
  },
  imageUrl: 'https://tagmepub.blob.core.windows.net/pubimg',
  survey_license:
    'MDg0OGRlODItZDkyYy00MzA1LWI1ZWQtYTg1MGJlYTZiNWE4OzE9MjAyNC0xMC0wNCwyPTIwMjQtMTAtMDQsND0yMDI0LTEwLTA0',
};
