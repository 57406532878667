import { animate, state, style, transition, trigger } from '@angular/animations';

import { TgxAnimationCurves, TgxAnimationDurations } from './defaults';

const slideInTop = trigger('slideInTop', [
  state(
    'void',
    style({
      transform: 'translate3d(0, -100%, 0)',
    }),
  ),

  state(
    '*',
    style({
      transform: 'translate3d(0, 0, 0)',
    }),
  ),

  // Prevent the transition if the state is false
  transition('void => false', []),

  // Transition
  transition('void => *', animate('{{timings}}'), {
    params: {
      timings: `${TgxAnimationDurations.entering} ${TgxAnimationCurves.deceleration}`,
    },
  }),
]);

const slideInBottom = trigger('slideInBottom', [
  state(
    'void',
    style({
      transform: 'translate3d(0, 100%, 0)',
    }),
  ),

  state(
    '*',
    style({
      transform: 'translate3d(0, 0, 0)',
    }),
  ),

  // Prevent the transition if the state is false
  transition('void => false', []),

  // Transition
  transition('void => *', animate('{{timings}}'), {
    params: {
      timings: `${TgxAnimationDurations.entering} ${TgxAnimationCurves.deceleration}`,
    },
  }),
]);

const slideInLeft = trigger('slideInLeft', [
  state(
    'void',
    style({
      transform: 'translate3d(-100%, 0, 0)',
    }),
  ),

  state(
    '*',
    style({
      transform: 'translate3d(0, 0, 0)',
    }),
  ),

  // Prevent the transition if the state is false
  transition('void => false', []),

  // Transition
  transition('void => *', animate('{{timings}}'), {
    params: {
      timings: `${TgxAnimationDurations.entering} ${TgxAnimationCurves.deceleration}`,
    },
  }),
]);

const slideInRight = trigger('slideInRight', [
  state(
    'void',
    style({
      transform: 'translate3d(100%, 0, 0)',
    }),
  ),

  state(
    '*',
    style({
      transform: 'translate3d(0, 0, 0)',
    }),
  ),

  // Prevent the transition if the state is false
  transition('void => false', []),

  // Transition
  transition('void => *', animate('{{timings}}'), {
    params: {
      timings: `${TgxAnimationDurations.entering} ${TgxAnimationCurves.deceleration}`,
    },
  }),
]);

const slideOutTop = trigger('slideOutTop', [
  state(
    '*',
    style({
      transform: 'translate3d(0, 0, 0)',
    }),
  ),

  state(
    'void',
    style({
      transform: 'translate3d(0, -100%, 0)',
    }),
  ),

  // Prevent the transition if the state is false
  transition('false => void', []),

  // Transition
  transition('* => void', animate('{{timings}}'), {
    params: {
      timings: `${TgxAnimationDurations.exiting} ${TgxAnimationCurves.acceleration}`,
    },
  }),
]);

const slideOutBottom = trigger('slideOutBottom', [
  state(
    '*',
    style({
      transform: 'translate3d(0, 0, 0)',
    }),
  ),

  state(
    'void',
    style({
      transform: 'translate3d(0, 100%, 0)',
    }),
  ),

  // Prevent the transition if the state is false
  transition('false => void', []),

  // Transition
  transition('* => void', animate('{{timings}}'), {
    params: {
      timings: `${TgxAnimationDurations.exiting} ${TgxAnimationCurves.acceleration}`,
    },
  }),
]);

const slideOutLeft = trigger('slideOutLeft', [
  state(
    '*',
    style({
      transform: 'translate3d(0, 0, 0)',
    }),
  ),

  state(
    'void',
    style({
      transform: 'translate3d(-100%, 0, 0)',
    }),
  ),

  // Prevent the transition if the state is false
  transition('false => void', []),

  // Transition
  transition('* => void', animate('{{timings}}'), {
    params: {
      timings: `${TgxAnimationDurations.exiting} ${TgxAnimationCurves.acceleration}`,
    },
  }),
]);

const slideOutRight = trigger('slideOutRight', [
  state(
    '*',
    style({
      transform: 'translate3d(0, 0, 0)',
    }),
  ),

  state(
    'void',
    style({
      transform: 'translate3d(100%, 0, 0)',
    }),
  ),

  // Prevent the transition if the state is false
  transition('false => void', []),

  // Transition
  transition('* => void', animate('{{timings}}'), {
    params: {
      timings: `${TgxAnimationDurations.exiting} ${TgxAnimationCurves.acceleration}`,
    },
  }),
]);

export {
  slideInTop,
  slideInBottom,
  slideInLeft,
  slideInRight,
  slideOutTop,
  slideOutBottom,
  slideOutLeft,
  slideOutRight,
};
