import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { NgClass } from '@angular/common';

import { Subject, takeUntil } from 'rxjs';

import { TgxNavigationComponent } from '../../navigation.component';
import { TgxNavigationService } from '../../navigation.service';
import { TgxNavigationItem } from '../../navigation.types';

@Component({
  selector: 'tgx-navigation-divider-item',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass],
})
export class TgxNavigationDividerItemComponent implements OnInit, OnDestroy {
  @Input() item!: TgxNavigationItem;
  @Input() name!: string;

  private _tgxNavigationComponent!: TgxNavigationComponent;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _tgxNavigationService: TgxNavigationService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Get the parent navigation component
    this._tgxNavigationComponent = this._tgxNavigationService.getComponent(this.name);

    // Subscribe to onRefreshed on the navigation component
    this._tgxNavigationComponent.onRefreshed.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      // Mark for check
      this._changeDetectorRef.markForCheck();
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
