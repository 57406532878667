import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { NgIf, NgTemplateOutlet } from '@angular/common';

import { RouterOutlet } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { Subject, takeUntil } from 'rxjs';

import { TgxFullscreenComponent, TgxLoadingBarComponent } from '@tagmedev/ui-sdk/components';

import { TgxNavigationService, TgxNavigationComponent } from '@tagmedev/ui-sdk/components';

import { TgxMediaWatcherService } from '@tagmedev/ui-sdk/services';

import { Navigation, NavigationService } from '@tagmedev/ui-sdk/providers';

import {
  TgxLanguagesComponent,
  TgxUserComponent,
  TgxThemeModeSwitcherComponent,
} from '../../common';

@Component({
  selector: 'tgx-dense-layout',
  templateUrl: './dense.component.html',
  styleUrls: ['./dense.component.css'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    TgxLoadingBarComponent,
    TgxNavigationComponent,
    MatButtonModule,
    MatIconModule,
    TgxFullscreenComponent,
    NgIf,
    NgTemplateOutlet,
    RouterOutlet,
    TgxLanguagesComponent,
    TgxUserComponent,
    TgxThemeModeSwitcherComponent,
  ],
})
export class DenseLayoutComponent implements OnInit, OnDestroy {
  isScreenSmall!: boolean;
  navigation: any; // Navigation;
  navigationAppearance: 'default' | 'dense' = 'dense';
  openNavigationWithHover: boolean = false;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _navigationService: NavigationService,
    private _tgxMediaWatcherService: TgxMediaWatcherService,
    private _tgxNavigationService: TgxNavigationService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for current year
   */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to navigation data
    this._navigationService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation: Navigation) => {
        this.navigation = navigation;
      });

    // Subscribe to media changes
    this._tgxMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('md');

        // Change the navigation appearance
        this.navigationAppearance = this.isScreenSmall ? 'default' : 'dense';
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation = this._tgxNavigationService.getComponent<TgxNavigationComponent>(name);

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }

  /**
   * Toggle the navigation appearance
   */
  toggleNavigationAppearance(): void {
    this.navigationAppearance = this.navigationAppearance === 'default' ? 'dense' : 'default';
  }
}
