<ng-template #breadcrumbSeparator>
  <div>&#47;</div>
</ng-template>

<ng-template #breadcrumbLabel let-breadcrumb>
  {{ breadcrumb.label | transloco }}

  @if (breadcrumb.complementLabel) {
    &nbsp;<span>&#91;{{ breadcrumb.complementLabel }}&#93;</span>
  }
</ng-template>

@if (breadcrumbs.length > 0) {
  <nav class="tgx-breadcrumb" aria-label="Breadcrumb">
    <ol class="tgx-breadcrumbs">
      <li>
        <a class="tgx-breadcrumb-item" [routerLink]="['/']">
          {{ 'ui_sdk.components.breadcrumb.home' | transloco }}
        </a>
      </li>

      <ng-container *ngTemplateOutlet="breadcrumbSeparator"></ng-container>

      @for (breadcrumb of breadcrumbs; track breadcrumb.url; let first = $first; let last = $last) {
        @if (!last) {
          <li>
            <a [routerLink]="breadcrumb.url" class="tgx-breadcrumb-item">
              <ng-container
                *ngTemplateOutlet="breadcrumbLabel; context: { $implicit: breadcrumb }"
              ></ng-container>
            </a>
          </li>
        }

        @if (last) {
          <li aria-current="page">
            <div class="tgx-breadcrumb-item-active">
              <span>
                <ng-container
                  *ngTemplateOutlet="breadcrumbLabel; context: { $implicit: breadcrumb }"
                ></ng-container>
              </span>
            </div>
          </li>
        }

        @if (!last) {
          <ng-container *ngTemplateOutlet="breadcrumbSeparator"></ng-container>
        }
      }
    </ol>
  </nav>
}
