<!-- Loading bar -->
<tgx-loading-bar></tgx-loading-bar>

<!-- Wrapper -->
<div class="tgx-empty-layout-wrapper">
  <!-- Content -->
  <div class="tgx-empty-layout-content">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>
</div>
