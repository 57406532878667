<div class="tgx-dialog">
  <!-- Dismiss button -->
  @if (data.dismissible) {
    <div class="tgx-dismiss-button-container">
      <button mat-icon-button [matDialogClose]="undefined">
        <mat-icon class="tgx-dismiss-button-icon" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
      </button>
    </div>
  }

  <!-- Content -->
  <div class="tgx-dialog-content">
    <!-- Icon -->
    @if (data.icon?.show) {
      <div
        class="tgx-dialog-content-icon-container"
        [ngClass]="{
          'primary-color': data.icon?.color === 'primary',
          'accent-color': data.icon?.color === 'accent',
          'warn-color': data.icon?.color === 'warn',
          'basic-color': data.icon?.color === 'basic',
          'info-color': data.icon?.color === 'info',
          'success-color': data.icon?.color === 'success',
          'warning-color': data.icon?.color === 'warning',
          'error-color': data.icon?.color === 'error'
        }"
      >
        @if (data.icon?.name; as dataIconName) {
          <mat-icon class="tgx-dialog-content-icon" [svgIcon]="dataIconName"></mat-icon>
        }
      </div>
    }

    @if (data.title || data.message) {
      <div class="tgx-dialog-content-text">
        <!-- Title -->
        @if (data.title) {
          <div class="tgx-dialog-content-title" [innerHTML]="data.title | transloco"></div>
        }

        <!-- Message -->
        @if (data.message) {
          <div class="tgx-dialog-content-message" [innerHTML]="data.message | transloco"></div>
        }
      </div>
    }
  </div>

  <!-- Actions -->
  @if (data.actions?.confirm?.show || data.actions?.cancel?.show) {
    <div class="tgx-dialog-actions">
      <!-- Cancel -->
      @if (data.actions?.cancel?.show) {
        <button mat-stroked-button [matDialogClose]="'cancelled'">
          {{ data.actions?.cancel?.label | transloco }}
        </button>
      }

      <!-- Confirm -->
      @if (data.actions?.confirm?.show) {
        <button
          mat-flat-button
          [color]="data.actions?.confirm?.color"
          [matDialogClose]="'confirmed'"
        >
          {{ data.actions?.confirm?.label | transloco }}
        </button>
      }
    </div>
  }
</div>
