<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="languages">
  <ng-container *ngTemplateOutlet="flagImage; context: { $implicit: activeLang }"></ng-container>
</button>

<!-- Language menu -->
<mat-menu [xPosition]="'before'" #languages="matMenu">
  <ng-container *ngFor="let lang of availableLangs; trackBy: trackByFn">
    <button mat-menu-item (click)="setActiveLang(lang.id)">
      <span class="tgx-languages-menu-item">
        <ng-container *ngTemplateOutlet="flagImage; context: { $implicit: lang.id }"></ng-container>
        <span class="tgx-language-label">{{ lang.label }}</span>
      </span>
    </button>
  </ng-container>
</mat-menu>

<!-- Flag image template -->
<ng-template let-lang #flagImage>
  <span class="tgx-languages-flag-template">
    <span class="tgx-flag-template-span"></span>
    <img
      class="tgx-flag-template-image"
      [src]="'@tagmedev/ui-sdk/assets/images/flags/' + flagCodes[lang].toUpperCase() + '.svg'"
      [alt]="'Flag image for ' + lang"
    />
  </span>
</ng-template>
