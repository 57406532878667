import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VenueService {
  private _baseUrl = environment.review_api;

  constructor(private _httpClient: HttpClient) {}

  findByAllowedUser(): Observable<any[]> {
    const endpoint = `${this._baseUrl}/venues`;
    return this._httpClient.get<any[]>(endpoint);
  }
}
