import { inject } from '@angular/core';

import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';

import { finalize, Observable, take } from 'rxjs';

import { TgxLoadingService } from './loading.service';

export const tgxLoadingInterceptor = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const tgxLoadingService = inject(TgxLoadingService);
  let handleRequestsAutomatically = false;

  tgxLoadingService.auto$.pipe(take(1)).subscribe(value => {
    handleRequestsAutomatically = value;
  });

  // If the Auto mode is turned off, do nothing
  if (!handleRequestsAutomatically) {
    return next(req);
  }

  // Set the loading status to true
  tgxLoadingService._setLoadingStatus(true, req.url);

  return next(req).pipe(
    finalize(() => {
      // Set the status to false if there are any errors or the request is completed
      tgxLoadingService._setLoadingStatus(false, req.url);
    }),
  );
};
